<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="LAW_ID"
            itemText="codeName"
            itemValue="code"
            label="법령"
            name="lawId"
            v-model="searchParam.lawId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="gubunTable"
      title="법령별 구분"
      tableId="gubunTable"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @innerBtnClicked="innerBtnClicked"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            label="엑셀업로드"
            icon="upload"
            @btnClicked="excelUploadData" />
          <c-btn 
            v-if="editable" 
            label="법령추가" 
            icon="add" 
            @btnClicked="addRowRaw" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'msortOrder'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            @datachange="datachange(props, col)"
          />
        </template>
        <template v-if="col.name === 'gubunName'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            @datachange="datachange(props, col)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="red-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  {{$language('구분별 항목 추가')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'law-register',

  data() {
    return {
      searchParam: {
        lawId: null,
        useFlag: 'Y',
      },
      grid: {
        merge: [
          { index: 0, colName: 'lawId' },
          { index: 1, colName: 'saiLawRegisterMasterId' },
          { index: 2, colName: 'saiLawRegisterMasterId' },
        ],
        columns: [
          
        ],
        data: [],
      },
      useFlagItems: [],
      saveData: [],
      isSave: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.lawRegister.item.list.url; // selectConfig.sai.lawRegister.gubun.list.url;
      this.saveUrl = transactionConfig.sai.lawRegister.gubun.save.url;
      // code setting
      // list setting
      this.setHeader();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'lawName',
          field: 'lawName',
          label: '법령명',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          innerBtn: true,
          btns: [
            { label: '', icon: 'add', color: 'light-blue', tooltip: this.$language('법령별 구분 추가') }, // 법령별 구분 추가
          ],
        },
        {
          required: true,
          name: 'gubunName',
          field: 'gubunName',
          label: '구분',
          align: 'left',
          style: 'width:120px',
          type: 'custom',
          sortable: false,
        },
        {
          name: 'msortOrder',
          field: 'msortOrder',
          label: '구분순번',
          align: 'center',
          sortable: true,
          style: 'width:60px',
          type: 'custom',
        },
        {
          required: true,
          name: 'itemName',
          field: 'itemName',
          label: '항목',
          type: 'text',
          align: 'left',
          style: 'width:150px',
          sortable: true,
        },
        {
          required: true,
          name: 'relevantClause',
          field: 'relevantClause',
          label: '관련조항',
          type: 'text',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'requirements',
          field: 'requirements',
          label: '요구사항',
          type: 'textarea',
          align: 'left',
          style: 'width:220px',
          sortable: true,
        },
        {
          name: 'applyConstruction',
          field: 'applyConstruction',
          label: '적용사항',
          type: 'text',
          align: 'left',
          style: 'width:140px',
          sortable: true,
        },
        {
          name: 'target',
          field: 'target',
          label: '적용대상',
          type: 'text',
          align: 'center',
          style: 'width:90px',
          sortable: true,
        },
        {
          name: 'relatedDocument',
          field: 'relatedDocument',
          label: '관련문서(기록)',
          type: 'textarea',
          align: 'left',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'useFlag',
          field: 'useFlag',
          label: '사용여부',
          type: 'check',
          sortable: true,
          align: 'center',
          style: 'width:40px',
          true: 'Y',
          false: 'N'
        },
        {
          name: 'sortOrder',
          field: 'sortOrder',
          label: '순번',
          align: 'center',
          sortable: true,
          style: 'width:40px',
          type: 'number',
        },
      ]
    },
    datachange(props) {
      if (props.row['mstEditFlag'] !== 'C') {
        props.row['mstEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name !== 'gubunName' && props.row['itemEditFlag'] !== 'C') {
        props.row['itemEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      if (col.name === 'lawName') {
        // 법령별 구분 추가
        this.grid.data.splice(props.rowIndex, 0, {
          saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
          lawId: props.row.lawId,
          lawName: props.row.lawName,
          gubunName: '',  // 구분
          useFlag: 'Y',  // 사용여부
          mstEditFlag: 'C',
          regUserId: this.$store.getters.user.userId,
          // 항목 정보
          saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
          sortOrder: 0,
          itemName: '',  // 항목
          relevantClause: '',  // 관련조항
          requirements: '',  // 요구사항
          applyConstruction: '',  // 적용사항
          target: '',  // 적용대상
          relatedDocument: '',  // 관련문서(기록)
          itemEditFlag: 'C'
        })
      } else {
        // 구분별 항목 추가
        this.grid.data.splice(props.rowIndex, 0, {
          saiLawRegisterMasterId: props.row.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
          lawId: props.row.lawId,
          lawName: props.row.lawName,
          gubunName: props.row.gubunName,  // 구분
          useFlag: props.row.useFlag,  // 사용여부
          regUserId: this.$store.getters.user.userId,
          sortOrder: 0,
          // 항목 정보
          saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
          itemName: '',  // 항목
          relevantClause: '',  // 관련조항
          requirements: '',  // 요구사항
          applyConstruction: '',  // 적용사항
          target: '',  // 적용대상
          relatedDocument: '',  // 관련문서(기록)
          itemEditFlag: 'C'
        })
      }
    },
    addRowRaw() {
      this.popupOptions.title = '법령';  
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./lawGubunPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLawGubunPopup;
    },
    closeLawGubunPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let _idx = this.$_.findIndex(this.grid.data, { lawId: item.code });
          _idx = _idx > -1 ? _idx : 0
          this.grid.data.splice(_idx, 0, {
            saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
            lawId: item.code,
            lawName: item.codeName,
            gubunName: '',  // 구분
            useFlag: 'Y',  // 사용여부
            mstEditFlag: 'C',
            regUserId: this.$store.getters.user.userId,
            msortOrder: 0,
            // 항목 정보
            saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
            itemName: '',  // 항목
            relevantClause: '',  // 관련조항
            requirements: '',  // 요구사항
            applyConstruction: '',  // 적용사항
            target: '',  // 적용대상
            relatedDocument: '',  // 관련문서(기록)
            itemEditFlag: 'C'
          })
        })
      }
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        /** [구분 - 항목] 데이터 분리 : 분리시 수정&등록된 데이터만 처리 */
        let saveData = this.grid.data.filter(item => {
          return item.mstEditFlag === 'C' || item.mstEditFlag === 'U' 
            || item.itemEditFlag === 'C' || item.itemEditFlag === 'U'
        });
        this.$set(this.$data, 'saveData', this.$_.map(this.$_.uniqBy(saveData, 'saiLawRegisterMasterId'), (mst) => {
          return {
            saiLawRegisterMasterId: mst.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
            lawId: mst.lawId,
            lawName: mst.lawName,
            gubunName: mst.gubunName,  // 구분
            useFlag: 'Y',  // 사용여부
            editFlag: mst.mstEditFlag,
            msortOrder: mst.msortOrder,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            items: this.$_.map(this.$_.filter(saveData, { saiLawRegisterMasterId: mst.saiLawRegisterMasterId }), (item) => {
              return {
                saiLawRegisterMasterId: mst.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
                saiLawRegisterMasterItemId: item.saiLawRegisterMasterItemId,  // 법규등록부 항목 일련번호
                itemName: item.itemName,  // 항목
                relevantClause: item.relevantClause,  // 관련조항
                requirements: item.requirements,  // 요구사항
                applyConstruction: item.applyConstruction,  // 적용사항
                target: item.target,  // 적용대상
                relatedDocument: item.relatedDocument,  // 관련문서(기록)
                useFlag: item.useFlag,  // 사용여부
                sortOrder: item.sortOrder,
                editFlag: item.itemEditFlag,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              }
            })
          }
        }));
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',  
            message: '저장하시겠습니까?',   
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.', 
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '법규등록부 업로드';
      this.popupOptions.target = () => import(`${'./lawRegisterExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let mst_data = [];
        let mst_data_targetName = [];
        this.$_.forEach(s_data, item => {
          item.useFlag = 'Y';
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          if (this.$_.indexOf(mst_data_targetName, item.lawId + item.gubunName) < 0) {
            mst_data_targetName.push(item.lawId + item.gubunName)
            mst_data.push({
              saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
              lawId: item.lawId,
              lawName: item.lawName,
              gubunName: item.gubunName,  // 구분
              useFlag: 'Y',  // 사용여부
              msortOrder: item.msortOrder,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              items: [],
            })
          }
        })
        this.$_.forEach(mst_data, _mst => {
          this.$_.forEach(s_data, item => {
            if ((_mst.lawId + _mst.gubunName) == (item.lawId + item.gubunName)) {
              item.useFlag = 'Y';
              item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
              _mst.items.push({
                saiLawRegisterMasterId: item.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
                saiLawRegisterMasterItemId: item.saiLawRegisterMasterItemId,  // 법규등록부 항목 일련번호
                itemName: item.itemName,  // 항목
                relevantClause: item.relevantClause,  // 관련조항
                requirements: item.requirements,  // 요구사항
                applyConstruction: item.applyConstruction,  // 적용사항
                target: item.target,  // 적용대상
                relatedDocument: item.relatedDocument,  // 관련문서(기록)
                useFlag: 'Y',  // 사용여부
                sortOrder: item.sortOrder,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              })
            }
          })
        })

        this.$http.url = this.saveUrl + '/excel';
        this.$http.type = 'POST';
        this.$http.param = mst_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    }
  }
};
</script>
